.popup {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 0;
	visibility: hidden;
	overflow-y: auto;
	overflow-x: hidden;
	transition: all 0.4s ease 0s;

	&.open {
		opacity: 1;
		visibility: visible;
		.popup__content{
			transform: perspective(600px) translate(0, 0) rotateX(0);
			opacity: 1;
		}
	}
	&._sending {
		.spinner-wrap{
			opacity: 1;
			visibility: visible;
			z-index: 100;
		}
	}

	&__body {
		min-height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px 10px;
	}

	&__content {
		position: relative;
		background: #fff;
		padding: 50px;
		max-width: 620px;
		border-radius: 30px;
		transform: perspective(600px) translate(0, 100%) rotateX(-45deg);
		transition: all 0.4s ease 0s;
		opacity: 0;
		overflow: hidden;
		@media (max-width: 600px) {
			padding: 50px 20px;
		}
	}

	&__swipe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 40px;
		&:before,&:after{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			background: #dce4e7;
			width: 20px;
			height: 4px;
			border-radius: 3px;
		}
		&:before{
			transform: translate(-90%,-50%) rotate(20deg);
		}
		&:after{
			transform: translate(-10%,-50%) rotate(-20deg);
		}
	}

	&__close {
		position: absolute;
		right: 10px;
		top: 10px;
		width: 50px;
		height: 50px;
		cursor: pointer;
		transition: all 0.2s ease 0s;
		&::before,
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 12px;
			background: #e0e0e0;
			transition: all 0.2s ease 0s;
		}
		&:after {
			width: 24px;
			height: 2px;
			transform: rotate(-45deg);
		}
		&:before {
			width: 24px;
			height: 2px;
			transform: rotate(45deg);
		}
		&:hover {
			transform: rotate(180deg) translate(3px, -3px);
			&::before,
			&::after {
				background: #212121;
			}
		}
	}

	&__title {
		font-weight: 700;
		font-size: 32px;
		line-height: 140%;
		text-align: center;
		text-transform: uppercase;
		margin: 0px 0px 30px 0px;
	}

	&__form {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__inputs {
		margin: 0px -10px 30px -10px;
		display: flex;
		flex-wrap: wrap;
	}

	&__label {
		flex: 0 0 50%;
		&:nth-of-type(3) {
			flex: 0 0 100%;
		}
		@media (max-width: 600px) {
			flex: 0 0 100%;
		}
	}

	&__input {
		border: 1px solid #dce4e7;
		background: #f2f8fb;
		border-radius: 4px;
	}

	&__textarea{
		display: none;
	}

	&__button {
		align-self: center;
	}
}

#thx{
	.popup__content{
		max-width: 400px;
	}
	.popup__title{
		margin: 20px 0 10px 0;
		font-size: 26px;
	}
	.popup__text{
		text-align: center;
		font-size: 20px;
		margin: 0px 0px 20px 0px;
	}
}