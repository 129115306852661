@font-face {
	font-family: "TT Norms";
	src: url("../fonts/TTNorms-Regular.woff2") format("woff2"), url("../fonts/TTNorms-Regular.woff") format("woff"),
		url("../fonts/TTNorms-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "TT Norms";
	src: url("../fonts/TTNorms-Bold.woff2") format("woff2"),
		url("../fonts/TTNorms-Bold.woff") format("woff"), url("../fonts/TTNorms-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}
