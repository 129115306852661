.header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 -10px;
		position: relative;
	}

	&__item {
		margin: 0px 10px;
		max-width: 320px;
		display: flex;
	}

	&__menu {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1 1 auto;
		@media (max-width:500px) {
			justify-content: flex-end;
		}
	}

	&__logo {
		width: 220px;
		height: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgb(255, 255, 255);
		position: relative;
		border-radius: 0 0 15px 0;
		box-shadow: 0px 50px 50px rgba(44, 49, 53, 0.2);
		margin: 0px 40px 0px 0px;
		img {
			max-width: 100%;
			display: inline-block;
			margin: auto 0 0 0;
		}
		&:after {
			content: "";
			position: absolute;
			bottom: -30px;
			left: 0;
			width: 0;
			height: 0;
			border-top: 0px solid transparent;
			border-left: 208px solid #fff;
			border-bottom: 30px solid transparent;
			border-radius: 0 0 0 15px;
		}
		&:before {
			content: "";
			position: absolute;
			bottom: -30px;
			left: 15px;
			height: 50px;
			width: 190px;
			background: #fff;
			border-radius: 15px;
			transform: rotate(-8deg);
			z-index: -1;
			box-shadow: 0px 10px 30px rgba(44, 49, 53, 0.2);
		}
		@media (max-width: 992px) {
			margin: 0;
		}
		@media (max-width: 767px) {
			height: 80px;
			width: 100px;
			border-radius: 0;
			z-index: 1;
			img {
				max-width: 72px;
			}
			&:after {
				display: none;
			}
			&:before {
				width: 100px;
				background: #fff;
				bottom: -13px;
				left: 0px;
				transform: rotate(0deg);
				height: 20px;
				z-index: 0;
				border-radius: 0 0 15px 15px;
				box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
			}
		}
		@media (max-width:360px) {
			width: 80px;
			&:before{
				width: 80px;
			}
		}
	}

	&__icon {
		width: 48px;
		height: 48px;
		flex: 0 0 48px;
		box-shadow: 0px 10px 30px rgba(44, 49, 53, 0.2);
		border-radius: 50%;
		margin: 0px 20px 0px 0px;
		background: url("../images/pin.webp") center no-repeat, #fff;
		align-self: center;
		@media (max-width: 992px) {
			display: none;
		}
	}

	&__text {
		@media (max-width: 992px) {
			display: none;
		}
	}

	&__label {
		font-weight: 700;
		line-height: 150%;
		font-size: 16px;
		margin: 0px 0px 8px 0px;
	}

	&__description {
		font-size: 16px;
		line-height: 150%;
	}

	&__item-wrap {
		text-align: right;
		max-width: 200px;
	}

	&__phone {
		font-weight: bold;
		font-size: 20px;
		line-height: 150%;
		color: #212121;
		text-decoration: none;
		white-space: nowrap;
		@media (min-width:1024px) {
			&:hover {
				text-decoration: underline;
			}
		}
		@media (max-width: 500px) {
			font-size: 40px;
			span {
				display: none;
			}
		}
	}

	&__schedule {
		margin: 4px 0px 0px 0px;
		color: #999999;
		font-size: 14px;
		line-height: 140%;
		@media (max-width: 500px) {
			display: none;
		}
	}

	&__button {
		@media (max-width: 767px) {
			font-size: 40px;
			padding: 10px 20px;
			span {
				display: none;
			}
		}
	}
}
._icon-phone::before{
	display: none;
	@media (max-width:500px) {
		display: block;
	}
}
._icon-callback::before {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
}
