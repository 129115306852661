// Общее
@import "./includes/normalize.scss";
@import "./includes/font.scss";
@import "./includes/iconsfont.scss";

// Общие классы
body {
	font-family: "TT Norms", Arial, sans-serif;
	color: #212121;
	font-size: 14px;
	position: relative;
	&.lock {
		overflow: hidden;
	}
}
.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
}
.main {
	@media (min-width: 768px) {
		padding: 0px 0px 96px 0px;
	}
}
.container {
	margin: 0 auto;
	max-width: 1180px;
	padding: 0px 20px;
}
.button {
	font-weight: bold;
	font-size: 15px;
	line-height: 140%;
	color: #ffffff;
	padding: 18px 34px;
	cursor: pointer;
	transition: all 0.2s ease 0s;
	background: #eeac19;
	border: 1px solid #eeac19;
	border-radius: 90px;
	outline: none;
	box-shadow: 0px 30px 50px rgba(44, 49, 53, 0.2);
	white-space: nowrap;
	display: inline-block;
	@media (min-width: 1024px) {
		&:hover {
			color: #212121;
			background: transparent;
			box-shadow: 0px 20px 20px rgba(44, 49, 53, 0.2);
			transform: scale(0.95);
		}
	}
	@media (max-width: 767px) {
		box-shadow: 0px 10px 20px rgba(44, 49, 53, 0.2);
	}
}
.title {
	font-weight: 400;
	font-size: 50px;
	line-height: 140%;
	text-transform: uppercase;
	span {
		font-weight: 700;
	}
	@media (max-width: 767px) {
		font-size: 30px;
	}
}
.subtitle {
	font-weight: 700;
	font-size: 32px;
	line-height: 140%;
	text-align: center;
	text-transform: uppercase;
	margin: 60px 0px 46px 0px;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: -60px;
		left: 50%;
		width: 40px;
		height: 40px;
		background: url("../images/star.svg") center/cover no-repeat;
		transform: translate(-50%, 0);
	}
}
.label {
	flex: 0 0 33.333%;
	padding: 0px 10px;
	display: inline-flex;
	flex-direction: column;
	margin: 0px 0px 30px 0px;
	span {
		font-weight: bold;
		font-size: 15px;
		line-height: 140%;
		margin: 0px 0px 10px 0px;
	}
	textarea {
		resize: none;
		height: 72px;
	}
	@media (max-width: 600px) {
		margin: 0px 0px 20px 0px;
	}
}
.select {
	border: 1px solid #dce4e7;
	border-radius: 4px;
	padding: 12px 20px;
	font-size: 16px;
	line-height: 140%;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	background: url("../images/select-arrow.svg") 91% center no-repeat, #f2f8fb;
	cursor: pointer;
	transition: all 0.2s ease 0s;
	@media (min-width: 1024px) {
		&:hover {
			border: 1px solid #dce4e7;
			box-shadow: 0px 0px 4px #5ac4fb;
		}
	}
}
.input {
	padding: 12px 20px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid transparent;
	transition: all 0.2s ease 0s;
	&::placeholder {
		font-size: 16px;
		line-height: 140%;
		color: #c9c9c9;
	}
	&._error {
		border: 1px solid #fcb3b3;
		background: #fdf0f0;
		&::placeholder {
			color: #fcb3b3;
		}
	}
	@media (min-width: 1024px) {
		&:hover {
			border: 1px solid #dce4e7;
			box-shadow: 0px 0px 4px #5ac4fb;
		}
	}
	@media (max-width: 992px) {
		padding: 12px;
	}
}

// Блоки
@import "./blocks/header.scss";
@import "./blocks/mainscreen.scss";
@import "./blocks/best.scss";
@import "./blocks/request.scss";
@import "./blocks/contacts.scss";
@import "./blocks/footer.scss";
@import "./blocks/popup.scss";
