.mainscreen {
	background: url('../images/transparent-bg.webp') center bottom/contain no-repeat,
	url('../images/main-bg.webp') center/cover no-repeat;
	min-height: 840px;
	padding: 200px 0px 0px 0px;
	@media (max-width:767px) {
		min-height: 700px;
		padding: 100px 0px 0px 0px;
	}
	&__body {
		text-align: center;
	}

	&__text {
		font-size: 22px;
		line-height: 140%;
		max-width: 600px;
		margin: 0 auto 50px auto;
	}
}