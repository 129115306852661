.spinner-wrap {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s ease 0s;
}
.spinner {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.spinner div {
	position: absolute;
	border: 4px solid #eeac19;
	opacity: 1;
	border-radius: 50%;
	animation: spin 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.spinner div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes spin {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

.request {
	position: relative;
	min-height: 1270px;
	padding: 180px 0px 160px 0px;
	background: url("../images/request-top-fade.webp") top center/contain no-repeat,
		url("../images/request-bottom-fade.webp") bottom center/contain no-repeat;
	@media (max-width: 1023px) {
		background: url("../images/balloon.webp") -270px 0px no-repeat, url("../images/seagull01.webp") 50% 80px no-repeat,
			url("../images/request-top-fade.webp") top center/contain no-repeat,
			url("../images/request-bottom-fade.webp") bottom center/contain no-repeat,
			url("../images/ocean.webp") center/cover no-repeat;
	}
	@media (max-width: 600px) {
		padding: 100px 0;
		background: url("../images/balloon.webp") -270px 0px no-repeat, url("../images/seagull01.webp") 50% 20px no-repeat,
			url("../images/request-top-fade.webp") top center/contain no-repeat,
			url("../images/request-bottom-fade.webp") bottom center/contain no-repeat,
			url("../images/ocean.webp") center/cover no-repeat;
	}

	&__text {
		font-size: 18px;
		line-height: 140%;
		text-align: center;
		margin: 0px auto 48px auto;
		max-width: 630px;
	}

	&__form {
		max-width: 860px;
		background: #ffffff;
		box-shadow: 0px 30px 60px rgba(30, 35, 66, 0.15);
		border-radius: 30px;
		overflow: hidden;
		margin: 0 auto;
		position: relative;
		&._sending {
			.spinner-wrap{
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__form-top {
		padding: 40px;
		display: flex;
		flex-wrap: wrap;
		margin: 0px -10px;
		@media (max-width: 767px) {
			label {
				flex: 0 0 50%;
			}
		}
		@media (max-width: 600px) {
			padding: 20px;
			label {
				flex: 0 0 100%;
			}
		}
	}

	&__datepicker {
		border: 1px solid #dce4e7;
		border-radius: 4px;
		padding: 12px 20px;
		font-size: 16px;
		line-height: 140%;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		appearance: none;
		min-width: 100%;
	}

	&__datepicker {
		&::-webkit-calendar-picker-indicator {
			opacity: 0;
			cursor: pointer;
		}
		background: url("../images/calendar.webp") 91% center no-repeat, #f2f8fb;
		min-height: 45px;
		transition: all 0.2s ease 0s;
		@media (min-width: 1024px) {
			&:hover {
				border: 1px solid #dce4e7;
				box-shadow: 0px 0px 4px #5ac4fb;
			}
		}
	}

	&__form-bottom {
		padding: 40px;
		display: flex;
		flex-direction: column;
		background: #f2f8fb;
		@media (max-width: 600px) {
			padding: 20px 20px 40px 20px;
		}
	}

	&__heading {
		font-weight: 700;
		font-size: 22px;
		line-height: 140%;
		text-align: center;
		text-transform: uppercase;
		margin: 0px 0px 16px 0px;
	}

	&__inputs {
		display: flex;
		flex-wrap: wrap;
		margin: 0px -10px;
		.request__label {
			&:last-child {
				flex: 1 1 auto;
			}
		}
		@media (max-width: 767px) {
			label {
				flex: 0 0 100%;
			}
		}
	}

	&__button {
		align-self: center;
	}
}
.parallax {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	@media (max-width: 1023px) {
		display: none;
	}
	&__list {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		li {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
	&__sky {
		position: absolute;
		width: 110%;
		height: 110%;
		top: -5%;
		left: -5%;
		background: url("../images/sky.webp") 0 0 / 150% 110% no-repeat;
	}
	&__sand {
		position: absolute;
		width: 110%;
		height: 110%;
		bottom: -5%;
		left: -5%;
		background: url("../images/sand.webp") 50% 100% / contain no-repeat;
	}

	&__balloon {
		position: absolute;
		top: 5%;
		left: 5%;
		z-index: 10;
		transform-origin: 50% 0;
		animation: lighthouse 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}
	&__seagull {
		position: absolute;
		z-index: -1;
	}
	&__seagull_1 {
		top: 10%;
		left: 27%;
		z-index: 0;
		animation: wave 7s 0.1s infinite linear;
	}
	&__seagull_2 {
		right: 5%;
		top: 5%;
		animation: wave 9s 0.1s infinite linear;
	}
}

@keyframes swing {
	0% {
		transform: rotateZ(10deg);
	}
	100% {
		transform: rotateZ(-10deg);
	}
}
@keyframes wave {
	0% {
		transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
	}
}
@keyframes lighthouse {
	0% {
		transform: translate3d(10%, 0, 0) rotateZ(3deg);
	}
	100% {
		transform: translate3d(-10%, 0, 0) rotateZ(-3deg);
	}
}
