.footer {
	padding: 20px 0;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(30, 35, 66, 0.15);

	@media (min-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 1;
	}
	&__body {
		display: flex;
		align-items: center;
		@media (max-width: 767px) {
			flex-direction: column;
		}
	}

	&__logo {
		img {
			max-width: 100%;
		}
		@media (min-width: 768px) {
			width: 70px;
		}
		@media (max-width: 767px) {
			width: 100px;
		}
	}

	&__text {
		padding: 0 50px;
		font-size: 14px;
		line-height: 150%;
		a {
			color: #212121;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		@media (max-width: 767px) {
			padding: 20px 0;
			text-align: center;
		}
	}

	&__socials {
		display: flex;
		margin: 0px 0px 0px auto;
		@media (max-width: 767px) {
			margin: 0;
		}
	}

	&__social {
		margin: 0px 0px 0px 16px;
		overflow: hidden;
		border-radius: 50%;
		height: 50px;
		width: 50px;
		img {
			max-width: 100%;
		}
		@media (max-width: 767px) {
			margin: 0 8px;
		}
	}
}
