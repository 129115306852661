@font-face {
	font-family: 'icons';
	src: url('../fonts/icons.eot?84k5dq');
	src: url('../fonts/icons.eot?84k5dq#iefix') format('embedded-opentype'),
		url('../fonts/icons.ttf?84k5dq') format('truetype'),
		url('../fonts/icons.woff?84k5dq') format('woff'),
		url('../fonts/icons.svg?84k5dq#icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="_icon-"]::before,
[class*=" _icon-"]::before {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icons' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

._icon-callback:before {
	content: "\e900";
}

._icon-phone:before {
	content: "\e901";
}