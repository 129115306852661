.contacts {
	padding: 50px 0 0 0;

	&__info {
		display: flex;
		justify-content: space-evenly;
		margin: 0px 0px 50px 0px;
		@media (max-width:767px) {
			flex-direction: column;
		}
	}

	&__item {
		text-align: center;
		margin: 0 10px;
		@media (max-width:767px) {
			margin: 0 0 20px 0;
		}
	}

	&__heading {
		font-weight: 700;
		font-size: 16px;
		line-height: 140%;
		text-transform: uppercase;
		color: #3596bc;
		margin: 0px 0px 10px 0px;
	}

	&__text {
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		color: #000000;
		a{
			color: #000;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	&__map {
		height: 400px;
		width: 100%;
		@media (max-width:600px) {
			height: 300px;
		}
	}
}