.best {
	&__body {
		display: flex;
		flex-direction: column;
		padding: 0px 0px 50px 0px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0px -15px;
		& > li {
			padding: 0px 15px 40px 15px;
			flex: 0 0 33.333%;
			min-width: 360px;
			display: flex;
		}
		@media (max-width:500px) {
			& > li {
				flex: none;
				min-width: auto;
				width: 100%;
			}
		}
	}

	&__card {
		border-radius: 30px;
		overflow: hidden;
		display: inline-block;
		box-shadow: 0px 30px 30px rgba(44, 49, 53, 0.2);
		transition: all 0.2s ease 0s;
		display: flex;
		flex-direction: column;
		@media (min-width:1024px) {
			&:hover{
				box-shadow: 0px 0px 10px rgba(44, 49, 53, 0.2);
				transform: scale(0.98);
				.best__location{
					color:#EEAC19;
				}
			}
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		padding: 0px 0px 66% 0px;
		img {
			transition: all 0.2s ease 0s;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
		@media (max-width:500px) {
			padding: 0px 0px 37% 0px;
			img{
				object-position: top center;
			}
		}
	}

	&__middle {
		padding: 30px;
		flex: 1 1 auto;
		@media (max-width:360px) {
			padding: 15px;
		}
	}

	&__location {
		font-weight: 700;
		font-size: 20px;
		line-height: 140%;
		text-align: center;
		text-transform: uppercase;
		color: #212121;
		transition: all 0.2s ease 0s;
		margin: 0px 0px 20px 0px;
		@media (max-width:360px) {
			margin: 0px 0px 10px 0px;
		}
	}

	&__text {
		line-height: 170%;
		color: #212121;
		margin: 0px 0px 12px 0px;
		span {
			font-weight: 700;
		}
	}

	&__conditions {
		li {
			line-height: 120%;
			color: #212121;
			margin: 0px 0px 8px 0px;
			padding: 0px 0px 0px 22px;
			background: url("../images/tick.webp") left top no-repeat;
		}
	}

	&__bottom {
		padding: 18px 28px 28px 28px;
		background: #f3f8fb;
		text-align: center;
		@media (max-width:500px) {
			padding: 28px 20px;
		}
	}

	&__contacts {
		font-size: 15px;
		line-height: 170%;
		color: #212121;
		margin: 0px 0px 10px 0px;
		span {
			font-weight: 700;
		}
		p:last-child {
			color: red;
			font-weight: 700;
		}
		@media (max-width:500px) {
			display: none;
		}
	}

	&__notice {
		font-size: 13px;
		line-height: 130%;
		color: #aaaaaa;
		margin: 0px 0px 20px 0px;
		@media (max-width:500px) {
			display: none;
		}
	}

	&__more-button {
		background: transparent;
		color: #212121;
		align-self: center;
		position: relative;
		z-index: 1;
		@media (min-width:1024px) {
			&:hover{
				color:#EEAC19;
			}
		}
	}
}
